.toma1-style{
   
    color: white;
    .txt-1{
        padding:5%;
        margin-top:2em;
        text-shadow: 2px 2px #050505;
        font-weight:400;
       font-size: 24px;
    }
    img{
        width: 100%;
    }
    .txt-2{
        text-shadow: 2px 2px #050505;
        font-weight:400;
       font-size: 20px;
    }
    .txt-3{
        margin-bottom: 4em;
        text-shadow: 2px 2px #050505;
        font-weight:400;
       font-size: 20px;
    }
}