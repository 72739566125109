.toma2-style {
  color: white;
  .txt-1 {
    margin-top: 2.5em;
    text-shadow: 2px 2px #050505;
    font-weight: 400;
    font-size: 24px;
  }
  img {
    margin-top: 2em;
    width: 65%;
  }
  .txt-2 {
    text-shadow: 2px 2px #050505;
    font-weight: 400;
    font-size: 20px;
  }
  .txt-3 {
    margin-bottom: 4em;
    margin-top: 2em;
    text-shadow: 2px 2px #050505;
    font-weight: 400;
    font-size: 20px;
  }
}
