 .options-style {
   justify-content: center;
padding:6%;
//   .column-1 {
      
//     text-align: -webkit-center;
//   }
//   .column-2 {
//     text-align: -webkit-center;
//   }
 }
