.container {
  margin: 2%;
  img {
    margin-top: 7em;
  }
  .texts {
    margin-top: 4em;
    .txt1 {
      font-family: OpenSans-Regular;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;

      text-align: center;

      color: #ffffff;
    }
    .txt2 {
      line-height: 0.9;

      font-family: OpenSans-Regular;
      font-style: normal;
      font-weight: 600;
      font-size: 46px;

      /* or 96% */

      text-align: center;

      color: #ffffff;

      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
