.palette {
  text-align: -webkit-center;
  margin: 2%;
  width: 80%;
  height: 50vh;
  border: 1px solid black;
  background-color: #fafafa;
  border-radius: 15px;
  @media (max-width: 600px) {
    width: 96%;
  }
  .mini-nav {
    border-top-left-radius: 15px 15px;
    border-top-right-radius: 15px 15px;
    height: 5vh;
    text-align-last: left;
    padding: 2% 0px 0px 2%;
    background-color: black;
    p {
      color: white;
      margin: 0 !important;
    }
  }
  img {
    height: 23vh;
  }
  .picker-color {
    display: flex;
    text-align: -webkit-center;
    width: 80%;
    height: 10vh;
    background-color: rgb(218, 15, 15);
  }
  .color1 {
    width: 80%;
    height: 10vh;
    background-color: #433673;
  
  }
  .color2 {
    width: 80%;
    height: 10vh;
    background-color: #6a7bd9;
  }
  .color3 {
    width: 80%;
    height: 10vh;
    background-color: #f24405;
  }
  .color4 {
    width: 80%;
    height: 10vh;
    background-color: #f21616;
  }
  .color5 {
    width: 80%;
    height: 10vh;
    background-color: #0d0d0d;
  }
}
