.inputT {
  margin: 2%;
  width: 80%;
  border: 1px solid black;
  background-color: #353535;
  border-radius: 15px;
  @media (max-width: 600px) {
    width: 96%;
  }
  .mini-nav {
    border-top-left-radius: 15px 15px;
    border-top-right-radius: 15px 15px;
    height: 5vh;
    text-align-last: left;
     padding: 2% 0px 0px 2%;;
    background-color: black;
    p {
      color: white;
      margin: 0 !important;
    }
  }
  .container-box {
    padding: 10px;
    margin-top: 2em;
    text-align-last: left;
    p {
      margin-left: 1em;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;

      color: #6774dc;
    }
    .last-col {
      margin: 0.5em 0% 0% 2%;

      border-left: 1px solid rgb(245, 240, 240);
    }
  }
}
