.typography {
  margin: 2%;
  width: 40%;
  border: 1px solid black;
  background-color: white;
  border-radius: 15px;
  @media (max-width: 600px) {
    width: 96%;
  }
  .mini-nav {
    border-top-left-radius: 15px 15px;
    border-top-right-radius: 15px 15px;
    height: 5vh;
    text-align-last: left;
     padding: 4% 0px 0px 5%;;
    background-color: black;
    p {
      color: white;
      margin: 0 !important;
    }
  }
  .title-sub {
    margin-left: 3.4em;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;

    text-decoration-line: underline;
    text-align-last: left;
    @media (max-width: 600px) {
      margin-left: 1.3em;
    }
  }
}
