@font-face {
  font-family: AvantGardeDemiBT;
  src: url(./fuentes/AvantGardeDemiBT.ttf);
}
@font-face {
  font-family: OpenSans-Regular;
  src: url(./fuentes/OpenSans-Regular.ttf);
}
@font-face {
  font-family: OpenSans-light;
  src: url(./fuentes/OPENSANS-LIGHT.TTF);
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: OpenSans-Regular, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    radial-gradient(113.88% 50% at 50% 50%, #0081e3 0%, #002039 100%);
  background-attachment: fixed;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
