@font-face {
  font-family: AvantGardeDemiBT;
  src: url(./../../fuentes/AvantGardeDemiBT.ttf);
}
@font-face {
  font-family: OpenSans-Regular;
  src: url(./../../fuentes/OpenSans-Regular.ttf);
}
@font-face {
  font-family: OpenSans-light;
  src: url(./../../fuentes/OPENSANS-LIGHT.TTF);
}
.screen1 {
  
  img{
   margin-top :4em;
   margin-bottom : 4em;
  }
  .box_one {
    .title1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;

      text-align: center;

      color: #ffffff;
    }
  }
  .title2{

font-style: normal;
font-weight: normal;
font-size: 20px;

color: #FFFFFF;
  }
}
