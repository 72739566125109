.verify-style {
  text-align: -webkit-center;

  color: white;
  margin-top:1.6em;
  p{
      font-size:24px;
      font-weight:600;
  }
  .div-img {
      margin: 1.5em 0em 2em 0em ;
    padding:4%;
    width: 65%;
    border-radius: 18px;
    background-color: white;
  }
  .div-img-icon{
      margin: 1em;
  }
}
