@font-face {
  font-family: AvantGardeDemiBT;
  src: url(././../../fuentes/AvantGardeDemiBT.ttf);
}
@font-face {
  font-family: OpenSans-Regular;
  src: url(././../../fuentes/OpenSans-Regular.ttf);
}
@font-face {
  font-family: OpenSans-light;
  src: url(././../../fuentes/OPENSANS-LIGHT.TTF);
}
.button {
  background: black;
  border-radius: 7px;

  color: white;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(180deg, #05050538 0%, #0f0f0f 15%);
}
.button1 {
  margin-bottom: 2%;

  color: #c4c4c4;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  border-radius: 7px;
}
.button2 {
  background: black;
  border-radius: 7px;

  color: white;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(180deg, #05050538 0%, #0f0f0f 15%);
}
.buttonStyle {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  border: none;
  border-radius: 8px;

  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: 800;
  padding: 5px;
  min-height: 26px;
  max-height: 48px;
}

.buttonStyleFileUpload {
  background: linear-gradient(
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  border: none;
  border-radius: 8px;

  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: 800;
  padding: 5px;
}

.buttonStyleFileUpload2 {
  background: linear-gradient(
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  border: none;
  border-radius: 8px;

  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: 800;
  padding: 5px;
  min-height: 26px;
  max-height: 48px;
}

.button3 {
  margin-bottom: 2%;

  color: #c4c4c4;
  font-size: 10px;
  font-weight: bold;
  border: 1px solid #c4c4c4;
  background-color: transparent;
  border-radius: 7px;
}

.buttonIns {
  border-radius: 7px;
  border-color: black;

  color: white;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(180deg, #05050500 0%, #0f0f0f75 15%);
}
