.div-input-style {

  input {
    width: 321px;
    height: 44px;
    padding: 4%;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: transparent;

    @media (max-width: 600px) {
  
      width: 140px;
      padding: 2%;
    }
  }
}
