.branding {
  margin: 2%;
  width: 40%;
  border: 1px solid black;
  background-color: white;
  border-radius: 15px;
  @media (max-width: 600px) {
    width: 96%;
  }
  .mini-nav {
    border-top-left-radius: 15px 15px;
    border-top-right-radius: 15px 15px;
    height: 5vh;
    text-align-last: left;
     padding: 4% 0px 0px 5%;;
    background-color: black;
    p {
      color: white;
      margin: 0 !important;
    }
  }
  .square-col {
    text-align: -webkit-center;
    .square-logo-color {
      border-radius: 26px;
      width: 60%;
      height: 95px;
      background-color: #463376;
      @media (max-width: 600px) {
        width: 90%;
      }
    }
    .square-logo-color2 {
      margin-top: 2em;
    }
  }
  .square-col2 {
    text-align: -webkit-center;
    .square-logo1 {
      border-radius: 10px;
      height: 40px;
      width: 60%;
      background-color: #463376;
      img {
        width: 100%;
      }
    }
    .square-logo2 {
      margin-top: 5em;
      border-radius: 10px;
      height: 40px;
      width: 60%;
      background-color: black;
      img {
        width: 100%;
      }
    }
  }

  .square-col3 {
    text-align: -webkit-center;
    .square-pallete {
      margin-top: 3em;
    }
  }
}
